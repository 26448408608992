import { ClientDetails, MpsKeysDataModels, LtiKeysDataModels, ProctoringSettings, AccountUsageSummary, ZohoAccountDetails, SubUsers, DetailedAccountUsageSummaryModels} from "../models/DataModels";

let clientDetails: ClientDetails[] = [];
let mpsKeysData: MpsKeysDataModels | null;
let ltiKeysData: LtiKeysDataModels | null;
let proctoringSettings: ProctoringSettings | null;
let accountUsageSummary: AccountUsageSummary[] = [];
let zohoAccountDetails: ZohoAccountDetails[] = [];
let subUsers: SubUsers[] = [];
let detailedAccountUsageSummary = new DetailedAccountUsageSummaryModels();
let clientDetailsForWhiteLabelling: ClientDetails[] = []

const dataStorage = {
    setClientDetails: (data: ClientDetails[]) => {
        clientDetails = data
    },
    getClientDetails: () => {
        return clientDetails;
    },
    setMpsKeysData: (data: MpsKeysDataModels | null) => {
        mpsKeysData = data;
    },
    getMpsKeysData: () => {
        return mpsKeysData;
    },
    setLtiKeysData: (data: LtiKeysDataModels | null) => {
        ltiKeysData = data;
    },
    getLtiKeysData: () => {
        return ltiKeysData;
    },
    setProctoringSettings: (data: ProctoringSettings | null) => {
        proctoringSettings = data;
    },
    getProctoringSettings: () => {
        return proctoringSettings;
    },
    setAccountUsageSummaryData: (data: AccountUsageSummary[]) => {
        accountUsageSummary= data
    },
    getAccountUsageSummaryData: () => {
        return accountUsageSummary;
    },
    setZohoAccountDetails: (data: ZohoAccountDetails[]) => {
        zohoAccountDetails = data
    },
    getZohoAccountDetails: () => {
        return zohoAccountDetails;
    },
    setSubUsers: (data: SubUsers[]) => {
        subUsers = data;
    },
    getSubUsers: () => {
        return subUsers;
    },
    setDetailedUsageSummaryData: (data: DetailedAccountUsageSummaryModels | null) => {
        detailedAccountUsageSummary = data ? data : new DetailedAccountUsageSummaryModels();
    },
    getDetailedUsageSummaryData: () => {
        return detailedAccountUsageSummary;
    },
    setClientDetailsForWhiteLabelling: (data: ClientDetails[]) => {
        clientDetailsForWhiteLabelling = data;
    },
    getClientDetailsForWhiteLabelling: () => {
        return clientDetailsForWhiteLabelling;
    }
}

export default dataStorage;
